<template>
  <v-card outlined class="ma-4">

    <!-- header -->
    <v-toolbar flat dense color="primary">
      <v-toolbar-title>{{ competition.info.name }}</v-toolbar-title>
    </v-toolbar>

    <!-- tabs -->
    <v-tabs :vertical="$vuetify.breakpoint.mdAndUp" show-arrows color="primary" v-if="state === 'ok'">

      <!-- navigation -->
      <v-tab>
        <v-icon left>mdi-information-outline</v-icon>
        INFORMACJE
        <v-spacer/>
      </v-tab>
      <v-tab>
        <v-icon left>mdi-trophy-outline</v-icon>
        PODIA
        <v-spacer/>
      </v-tab>
      <v-tab>
        <v-icon left>mdi-format-list-numbered</v-icon>
        WYNIKI
        <v-spacer/>
      </v-tab>
      <!--<v-tab>
        <v-icon left>mdi-chart-box-outline</v-icon>
        STATYSTYKI
        <v-spacer/>
      </v-tab>-->

      <!-- info -->
      <v-tab-item :class="$vuetify.breakpoint.mdAndUp ? 'border-left-' + theme : ''">
        <v-card flat min-height="250">
          <v-card-text>
            <v-row>
              <v-col class="pa-4" style="text-align: center">
                <span class="title">Data rozpoczęcia</span><br>
                <span class="subtitle-1">{{ $utils.dates.parseDate(competition.info.start_date) }}</span>
              </v-col>
              <v-col class="pa-4" style="text-align: center">
                <span class="title">Data zakończenia</span><br>
                <span class="subtitle-1">{{ $utils.dates.parseDate(competition.info.end_date) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-4" style="text-align: center">
                <span class="title">Zawodnicy</span><br>
                <span class="subtitle-1">{{ competition.info.competitors }}</span>
              </v-col>
              <v-col class="pa-4" style="text-align: center">
                <span class="title">Konkurencje</span><br>
                <span class="subtitle-1">
                  <v-icon left v-for="event in competition.events" :key="event" color="primary">
                    {{ $utils.events.getEventIcon(event) }}
                  </v-icon>
                </span>
              </v-col>
            </v-row>
            <v-row class="mt-8">
              <v-btn elevation="0" style="text-transform: unset" class="ma-2" color="primary"
                     @click="openWebsiteInNewTab(competition.info.website)">Strona zawodów
              </v-btn>
              <v-btn elevation="0" style="text-transform: unset" class="ma-2" color="primary"
                     @click="openWebsiteInNewTab('https://live.rubiart.pl/competitions/' + competition.info.live_id)">
                Wyniki live
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider v-if="competition.info.series_id != null"/>
        <v-card v-if="competition.info.series_id != null">
          <v-card-text>
            <v-row>
              <v-col class="text-left">
                Zawody są częścią cyklu: <b>{{ competition.info.series_name }}</b>
              </v-col>
              <v-col class="text-right">
                <v-btn elevation="0" style="text-transform: unset" color="primary"
                       @click="routeToSeries(competition.info.series_id)">
                  Klasyfikacja
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- podiums -->
      <v-tab-item :class="$vuetify.breakpoint.mdAndUp ? 'border-left-' + theme : ''">
        <v-card flat min-height="300">
          <v-card-text>
            <span v-for="podium in competition.podiums" :key="podium.event">
              <v-toolbar-title>
                <v-icon left color="primary">{{ $utils.events.getEventIcon(podium.event) }}</v-icon>
                {{ $utils.events.getEventName(podium.event) }}
              </v-toolbar-title>
            <v-simple-table dense class="mb-8">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-right">#</th>
                  <th class="text-center">&nbsp;</th>
                  <th class="text-left">Zawodnik</th>
                  <th class="text-center">Najlepsze</th>
                  <th class="text-center">Średnia</th>
                  <th class="text-center" v-if="$vuetify.breakpoint.mdAndUp">&nbsp;</th>
                  <th class="text-center" v-if="$vuetify.breakpoint.mdAndUp" colspan="5">Ułożenia</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="result in podium.results" :key="result['ra_id']" style="cursor: pointer"
                    @click="$router.push('/competitors/' + result['ra_id'])">
                  <td class="text-right">{{ result.ranking }}</td>
                  <td class="text-center">
                    <v-icon v-if="result.ranking === 1" color="yellow darken-1">mdi-medal</v-icon>
                    <v-icon v-if="result.ranking === 2" color="grey darken-1">mdi-medal</v-icon>
                    <v-icon v-if="result.ranking === 3" color="brown darken-1">mdi-medal</v-icon>
                  </td>
                  <td class="text-left" nowrap>{{ result['competitor_name'] }}</td>
                  <td class="text-center font-weight-bold">
                    {{ $utils.results.parseResult(result['best'], result['event']) }}
                  </td>
                  <td class="text-center font-weight-bold">
                    {{ $utils.results.parseResult(result['average'], result['event']) }}
                  </td>
                  <td class="text-center" v-if="$vuetify.breakpoint.mdAndUp">&nbsp;</td>
                  <td class="text-center" v-if="$vuetify.breakpoint.mdAndUp">
                    {{ $utils.results.parseResult(result['a1'], result['event']) }}
                  </td>
                  <td class="text-center" v-if="$vuetify.breakpoint.mdAndUp">
                    {{ $utils.results.parseResult(result['a2'], result['event']) }}
                  </td>
                  <td class="text-center" v-if="$vuetify.breakpoint.mdAndUp">
                    {{ $utils.results.parseResult(result['a3'], result['event']) }}
                  </td>
                  <td class="text-center" v-if="$vuetify.breakpoint.mdAndUp">
                    {{ $utils.results.parseResult(result['a4'], result['event']) }}
                  </td>
                  <td class="text-center" v-if="$vuetify.breakpoint.mdAndUp">
                    {{ $utils.results.parseResult(result['a5'], result['event']) }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            </span>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- results -->
      <v-tab-item :class="$vuetify.breakpoint.mdAndUp ? 'border-left-' + theme : ''">
        <v-card flat min-height="300">
          <v-card-text>
            <v-toolbar-title>Konkurencja</v-toolbar-title>
            <v-chip-group column mandatory v-model="results_event">
              <v-chip v-for="(event, index) in results_events" :key="index" outlined
                      :color="index === results_event ? 'primary' : ''"
                      class="ma-1" style="padding-top: 2px; padding-left: 13px"
                      @click="results_rounds = competition.results[event]['rounds']; results_round = 0">
                <v-icon>{{ $utils.events.getEventIcon(event) }}</v-icon>
              </v-chip>
            </v-chip-group>
            <v-toolbar-title>Runda</v-toolbar-title>
            <v-chip-group column mandatory v-model="results_round">
              <v-chip v-for="(round, index) in results_rounds" :key="index" outlined
                      :color="index === results_round ? 'primary' : ''"
                      class="ma-1" style="padding-top: 2px; padding-left: 13px">
                {{ $utils.events.getRoundName(round) }}
              </v-chip>
            </v-chip-group>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-right">#</th>
                  <th class="text-left">Zawodnik</th>
                  <th class="text-center">Najlepsze</th>
                  <th class="text-center">Średnia</th>
                  <th>&nbsp;</th>
                  <th class="text-center" colspan="5">Ułożenia</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="result in competition.results[results_events[results_event]]['r' + results_rounds[results_round]]"
                    :key="result['ra_id']" style="cursor: pointer" @click="$router.push('/competitors/' + result['ra_id'])">
                  <td class="text-right">{{ result['ranking'] }}</td>
                  <td class="text-left" nowrap>{{ result['competitor_name'] }}</td>
                  <td class="text-center font-weight-bold">
                    {{ $utils.results.parseResult(result['best'], result['event']) }}
                  </td>
                  <td class="text-center font-weight-bold">
                    {{ $utils.results.parseResult(result['average'], result['event']) }}
                  </td>
                  <td>&nbsp;</td>
                  <td class="text-center">{{ $utils.results.parseResult(result['a1'], result['event']) }}</td>
                  <td class="text-center">{{ $utils.results.parseResult(result['a2'], result['event']) }}</td>
                  <td class="text-center">{{ $utils.results.parseResult(result['a3'], result['event']) }}</td>
                  <td class="text-center">{{ $utils.results.parseResult(result['a4'], result['event']) }}</td>
                  <td class="text-center">{{ $utils.results.parseResult(result['a5'], result['event']) }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- statistics -->
      <!--<v-tab-item :class="$vuetify.breakpoint.mdAndUp ? 'border-left-' + theme : ''">
        <v-card flat min-height="300">
          <v-card-text>
            Aktualnie trwają prace nad statystykami.
          </v-card-text>
        </v-card>
      </v-tab-item>-->

    </v-tabs>

    <!-- loading -->
    <v-skeleton-loader v-if="state === 'loading'" type="card"/>

    <!-- error -->
    <v-alert v-if="state === 'error'">
      <v-icon left color="error">mdi-alert-circle-outline</v-icon>
      Wystąpił nieoczekiwany błąd.
    </v-alert>

    <!-- ongoing -->
    <v-alert v-if="state === 'ongoing'">
      <v-icon left color="warning">mdi-alert-circle-outline</v-icon>
      Wyniki z zawodów nie zostały jeszcze wprowadzone do systemu.
    </v-alert>

  </v-card>
</template>

<script>
export default {
  name: 'Competition',
  data: () => ({
    state: 'loading',
    competition: {info: {name: ''}},
    results_events: [],
    results_event: 0,
    results_rounds: [],
    results_round: 0
  }),
  created: function () {
    this.$http.post('/api/competition', {id: parseInt(this.$route.params.id)}).then((res) => {
      this.competition.info = res.data.info;
      if (Object.keys(this.competition.info).length !== 0) {
        this.competition.events = this.getEvents(res.data.results);
        this.competition.results = this.parseResults(res.data.results, this.competition.events);
        this.competition.podiums = this.parsePodiums(res.data.results);
        this.results_events = this.competition.events;
        if (this.results_events.length === 0)
          return this.state = 'ongoing';
        else {
          this.results_rounds = this.competition.results[this.results_events[this.results_event]]['rounds'];
          this.state = 'ok';
        }
      } else this.state = 'error';
    }).catch((e) => {
      this.state = 'error';
      console.log(e);
    });
  },
  methods: {
    openWebsiteInNewTab(url) {
      window.open(url, '_blank');
    },
    routeToSeries(seriesId) {
      this.$router.push('/series/' + seriesId);
    },
    getEvents(results) {
      let events = []
      for (let i = 0; i < results.length; i++)
        events.push(results[i]['event']);
      events = events.sort().filter((item, index, array) => !index || item !== array[index - 1]);
      return events.sort((a, b) => (+a) - (+b));
    },
    parsePodiums(results) {
      let parsedPodiums = [];
      for (let i = 0; i < 17; i++)
        parsedPodiums.push({event: i, podium: false, results: []})
      for (let i = 0; i < results.length; i++) {
        if (results[i]['round'] === 4 && results[i]['ranking'] < 4 && results[i]['best'] > 0) {
          parsedPodiums[results[i]['event']].podium = true;
          parsedPodiums[results[i]['event']].results.push(results[i]);
        }
      }
      return parsedPodiums.filter((event) => event.podium);
    },
    parseResults(results) {
      let resultsByEvent = [];
      for (let i = 0; i < 17; i++)
        resultsByEvent.push({round: 0, rounds: [], r1: [], r2: [], r3: [], r4: []});
      for (let i = 0; i < results.length; i++)
        resultsByEvent[results[i]['event']]['r' + (results[i]['round'])].push(results[i]);
      for (let i = 0; i < resultsByEvent.length; i++)
        for (let j = 1; j < 5; j++)
          if (resultsByEvent[i]['r' + j].length !== 0)
            resultsByEvent[i]['rounds'].push(j);
      return resultsByEvent;
    }
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    }
  }
};
</script>

<style scoped>
.border-left-light {
  border-left: 1px solid #e0e0e0;
}

.border-left-dark {
  border-left: 1px solid #2f2f2f;
}

.theme--light.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #00000010;
}

.theme--dark.v-data-table tbody tr:nth-of-type(odd) {
  background-color: #ffffff10;
}
</style>
